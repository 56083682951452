import React, { FC } from 'react';

import { GatsbySeo } from 'gatsby-plugin-next-seo/lib';
import { graphql } from 'gatsby';

import { useIntl } from 'gatsby-plugin-intl';

import { AllWpProduct, AllWpTopBar } from '../../types';
import { PageType } from '@constants/pageType.constants';
import { getLink } from '@helpers/linking.helper';
import { convertStringToBoolean } from '../../app/product/product.helper';

import Accessories from '@components/accessories/Accessories.component';
import Thumbnail from '@assets/thumbnails/avionaut_thumbnail.jpg';
import { LogoJsonLd } from 'gatsby-plugin-next-seo';

interface IAccessoriesTemplate {
  data: {
    allWpAccessory: AllWpProduct
    allWpTopBar: AllWpTopBar;
  }
  pageContext: {
    localePath: string
  }
}

const AccessoriesTemplate: FC<IAccessoriesTemplate> = (props) => {
  const intl = useIntl();
  console.log(props)

  const {
    data: {
      allWpAccessory,
      allWpTopBar
    },
  } = props;

  const isTopBarVisible = allWpTopBar.edges[0].node && convertStringToBoolean[allWpTopBar.edges[0].node.topBarData.istopbarvisible]

  return (
    <>
      <LogoJsonLd
        logo={'https://avionaut.com/avionaut.svg'}
        url={`https://avionaut.com${getLink(intl.locale, PageType.START)}`}
      />
      <GatsbySeo
        htmlAttributes={{
          language: intl.locale
        }}
        language={intl.locale}
        languageAlternates={[
          {
            hrefLang: 'x-default',
            href: `https://avionaut.com${getLink(intl.locale, PageType.ACCESSORIES)}`,
          },
          {
            hrefLang: 'en',
            href: `https://avionaut.com${getLink('en', PageType.ACCESSORIES)}`,
          },
          {
            hrefLang: 'pl',
            href: `https://avionaut.com${getLink('pl', PageType.ACCESSORIES)}`,
          },
          {
            hrefLang: 'de',
            href: `https://avionaut.com${getLink('de', PageType.ACCESSORIES)}`,
          },
          {
            hrefLang: 'cz',
            href: `https://avionaut.com${getLink('cz', PageType.ACCESSORIES)}`,
          },
          {
            hrefLang: 'hr',
            href: `https://avionaut.com${getLink('hr', PageType.ACCESSORIES)}`,
          },
          {
            hrefLang: 'es',
            href: `https://avionaut.com${getLink('es', PageType.ACCESSORIES)}`,
          },
          {
            hrefLang: 'fr',
            href: `https://avionaut.com${getLink('fr', PageType.ACCESSORIES)}`,
          },
        ]}
        canonical={`https://avionaut.com${getLink(intl.locale, PageType.ACCESSORIES)}/`}
        title={`${intl.locale === 'pl' || intl.locale === 'de' ? '': 'Avionaut - '}${intl.formatMessage({ id: 'metaTitle__accessories' })}`}
        description={intl.formatMessage({ id: 'metaDescription__accessories' })}
        metaTags={[
          {
            property: 'thumbnail',
            content: Thumbnail,
          },
          {
            property: 'og:title',
            content: intl.formatMessage({ id: 'metaTitle__accessories' })
          },
          {
            property: 'og:description',
            content: intl.formatMessage({ id: 'metaDescription__accessories' }),
          },
          {
            property: 'twitter:title',
            content: intl.formatMessage({ id: 'metaTitle__accessories' })
          },
          {
            property: 'twitter:description',
            content: intl.formatMessage({ id: 'metaDescription__accessories' })
          },
        ]}
      />
      <Accessories
        header={intl.formatMessage({ id: 'accessories__accessoriesList' })}
        noAccessoriesFoundText={intl.formatMessage({ id: 'accessories__noAccessoriesFound' })}
        allWpProduct={allWpAccessory}
        localePath={intl.locale}
        isTopBarVisible={isTopBarVisible}
      />
    </>
  );
};

export const query = graphql`
  query GetAccessoriesList($locale: String) {
    allWpAccessory(filter: {locale: {locale: {eq: $locale}}}) {
      edges {
        node {
          locale {
            locale
          }
          productMetaData {
            productname
            thumbnail {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED)
                }
              }
            }
          }
          productData {
            productavailablecolors {
              ... on WpColor {
                id
                colorProperties {
                  colorhex
                  colorname
                  colorparameter
                }
              }
            }
            thumbnailphotos {
              productcolorversion {
                ... on WpColor {
                  colorProperties {
                    colorhex
                    colorname
                    colorparameter
                  }
                }
              }
              photo {
                id
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, quality: 100)
                  }
                }
              }
            }
            tags
          }
          slug
        }
      }
    }
    allWpTopBar(filter: {locale: {locale: {eq: $locale}}}) {
      edges {
        node {
          id
          locale {
            id
            locale
          }
          topBarData {
            buttoncta
            buttontext
            istopbarvisible
            promotext
            promotextmobile
          }
        }
      }
    }
  }
`;

export default AccessoriesTemplate;
