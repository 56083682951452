import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import { AllWpProduct, ListedProductEdge } from 'types';
import { PageType } from '@constants/pageType.constants';

import { Container } from '../container/Container.component';
import { Header } from '../header/Header.component';
import Product from '../product/Product.component';
import Tag from '../product/Tag.component';

import s from './Accessories.module.scss';

interface IAccessories {
  header: string | ReactNode;
  noAccessoriesFoundText: string;
  allWpProduct: AllWpProduct;
  localePath: string;
  isTopBarVisible: boolean;
}

const Accessories: FC<IAccessories> = ({ header, noAccessoriesFoundText, allWpProduct, isTopBarVisible }) => {
  const [wpAccessories, setWpAccessories] = useState<AllWpProduct>(allWpProduct);
  const [isAccessoriesListEmpty, setIsAccessoriesListEmpty] = useState<boolean>(false);
  const intl = useIntl();

  const tagTranslation: string[] = [
    intl.formatMessage({ id: 'tag__0' }),
    intl.formatMessage({ id: 'tag__1' }),
    intl.formatMessage({ id: 'tag__2' }),
  ];
  
  const checkAccessoriesList = () => {
    const filteredAccessories: AllWpProduct = { edges: [] };
    filteredAccessories.edges = [...allWpProduct.edges];

    filteredAccessories.edges.length === 0 ? setIsAccessoriesListEmpty(true) : setIsAccessoriesListEmpty(false);
    setWpAccessories(filteredAccessories);
  };

  useEffect(() => {
    checkAccessoriesList()
  }, [allWpProduct]);
  

  return (
    <div className={`${s.accessories} ${isTopBarVisible ? s.lowerAccessories : ''}`} id={'footer-anchor'}>
      <Container>
        <div className={s.accessories__header}>
          <Header isMainHeader>{header}</Header>
        </div>
        {
          isAccessoriesListEmpty
            ? <div className={s.accessories__info}>{noAccessoriesFoundText}</div>
            : ''
        }
        <div className={s.accessories__list}>
          {
            wpAccessories.edges.map((accessory: ListedProductEdge, index) => {
              return (
                <Product
                  name={accessory.node.productMetaData.productname}
                  colors={accessory.node.productData.productavailablecolors}
                  colorVisible
                  pageType={PageType.ACCESSORY}
                  slug={accessory.node.slug}
                  thumbnail={accessory.node.productMetaData.thumbnail}
                  key={index}
                  thumbnailPhotos={accessory.node.productData.thumbnailphotos}
                >
                  {accessory.node.productData.tags && accessory.node.productData.tags.map((tag: string) => {
                    return (
                      <Tag key={tag} value={tagTranslation[parseInt(tag)]} />
                    );
                  })}
                </Product>
              );
            })
          }
        </div>
      </Container>
    </div>
  );
};

export default Accessories;
